












import { computed, defineComponent } from "@vue/composition-api"

export default defineComponent({
  name: "SuccessAlert",

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    dismissable: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const showAlert = computed({
      get() {
        return props.value
      },
      set(newValue) {
        emit("input", newValue)
      },
    })

    return {
      showAlert,
    }
  },
})
